import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

import {
  PostTitle,
  PostContainer,
  PostImgContainer,
  PostContents,
  PostAuthor,
  PostDate,
  PostSocialsContainer,
} from 'components/BlogComponents'

const Blog = () => {
  const { t } = useTranslation('post5')
  const url = typeof window !== 'undefined' ? window.location.href : null

  return (
    <Layout>
      <Seo title={t('post5Title')} description={t('post5Paragraph1')} />
      <PostContainer>
        <PostTitle>
          <h1>{t('post5Title')}</h1>
        </PostTitle>
        <PostAuthor>
          <p>{t('post5Author')}</p>
        </PostAuthor>
        <PostDate>
          <p>{t('post5Date')}</p>
        </PostDate>
        <PostSocialsContainer>
          <FacebookShareButton
            url={url}
            quote={t('post5Paragraph1')}
            hashtag="#fluentworlds"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </PostSocialsContainer>

        <PostImgContainer>
          <StaticImage
            src="../graphics/tongue_twister.jpg"
            alt="Woman trying a tongue twister"
          />
        </PostImgContainer>

        <PostContents>
          <p>{t('post5Paragraph1')}</p>
          <ul>
            <li>
              <strong>{t('post5ListItem1')}</strong>
              <p>
                <em>{t('post5ListPara1')}</em>
              </p>
              <p>{t('post5ListPara2')}</p>
            </li>
            <li>
              <strong>{t('post5ListItem2')}</strong>
              <p>
                <em>{t('post5ListPara3')}</em>
              </p>
              <p>{t('post5ListPara4')}</p>
            </li>
            <li>
              <strong>{t('post5ListItem3')}</strong>
              <p>
                <em>{t('post5ListPara5')}</em>
              </p>
              <p>{t('post5ListPara6')}</p>
            </li>
            <li>
              <strong>{t('post5ListItem4')}</strong>
              <p>
                <em>{t('post5ListPara7')}</em>
              </p>
              <p>{t('post5ListPara8')}</p>
            </li>
          </ul>
          <p>
            {t('post5Paragraph2')}
            <a href="https://youtu.be/ttgFlw1YgYI">{t('post5VideoLinkText')}</a>
          </p>
        </PostContents>
      </PostContainer>
    </Layout>
  )
}

export default Blog
